import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';

import css from './P.module.css';

export const P = React.forwardRef((props, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
  const { className, rootClassName, ...otherProps } = props;
  const classes = classNames(rootClassName || css.p, className);

  // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
  return <p className={classes} {...otherProps} ref={ref} />;
});

P.displayName = 'P';

P.defaultProps = {
  // @ts-expect-error TS(2322) FIXME: Type '{ rootClassName: null; className: null; chil... Remove this comment to see the full error message
  rootClassName: null,
  className: null,
  children: null,
};

P.propTypes = {
  // @ts-expect-error TS(2322) FIXME: Type '{ rootClassName: Requireable<string>; classN... Remove this comment to see the full error message
  rootClassName: string,
  className: string,
  children: node,
};
