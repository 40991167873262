import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';

import css from './List.module.css';

const defaultPropsList = {
  rootClassName: null,
  className: null,
};

const propTypesList = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
};

export const Ul = React.forwardRef((props, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
  const { className, rootClassName, ...otherProps } = props;
  const classes = classNames(rootClassName || css.ul, className);

  // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
  return <ul className={classes} {...otherProps} ref={ref} />;
});
Ul.displayName = 'Ul';
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: null; className: null; }' h... Remove this comment to see the full error message
Ul.defaultProps = defaultPropsList;
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: Requireable<string>; classN... Remove this comment to see the full error message
Ul.propTypes = propTypesList;

export const Ol = React.forwardRef((props, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
  const { className, rootClassName, ...otherProps } = props;
  const classes = classNames(rootClassName || css.ol, className);

  // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
  return <ol className={classes} {...otherProps} ref={ref} />;
});
Ol.displayName = 'Ol';
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: null; className: null; }' h... Remove this comment to see the full error message
Ol.defaultProps = defaultPropsList;
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: Requireable<string>; classN... Remove this comment to see the full error message
Ol.propTypes = propTypesList;

export const Li = React.forwardRef((props, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
  const { className, rootClassName, ...otherProps } = props;
  const classes = classNames(rootClassName || css.li, className);

  // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
  return <li className={classes} {...otherProps} ref={ref} />;
});
Li.displayName = 'Li';
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: null; className: null; }' h... Remove this comment to see the full error message
Li.defaultProps = defaultPropsList;
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: Requireable<string>; classN... Remove this comment to see the full error message
Li.propTypes = propTypesList;
