import classNames from 'classnames';
import { func, node, object, shape, string } from 'prop-types';
import React from 'react';

import Field from '../../Field';
import BlockContainer from '../BlockContainer';
import css from './BlockSocialMediaLink.module.css';

const BlockSocialMediaLink = props => {
  const { blockId, className, rootClassName, link, options } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; id: any; className: str... Remove this comment to see the full error message
    <BlockContainer id={blockId} className={classes}>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ data: any; options: any; className: any; }... Remove this comment to see the full error message */}
      <Field data={link} options={options} className={css.link} />
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

const propTypeLink = shape({
  fieldType: string,
  platform: string,
  url: string,
});

BlockSocialMediaLink.defaultProps = {
  className: null,
  rootClassName: null,
  link: null,
};

BlockSocialMediaLink.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  link: propTypeLink,
  options: propTypeOption,
};

export default BlockSocialMediaLink;
