import { func, node, object, shape, string } from 'prop-types';
import toast from 'react-hot-toast';

import { NamedLink, PrimaryButton } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import SectionContainer from '../SectionContainer';
import { useSubscribeToNewsletter } from './SectionFooter.hooks';
import css from './SectionFooter.module.css';
import { IconInstagram } from 'assets/icons';
import { InfoPageTab } from 'containers/InfoPage/InfoPage';
import { LegalPageTab } from 'containers/LegalPage/LegalPage';

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const { sectionId, className, rootClassName, appearance, options } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const { mutateAsync: subscribeToNewsletter } = useSubscribeToNewsletter();

  const handleSubscribe = async event => {
    event.preventDefault();
    const email = event.target.footerSubscribe.value;
    const response: any = await subscribeToNewsletter({ email });
    toast.success(response.message);
  };

  // use block builder instead of mapping blocks manually

  return (
    <SectionContainer
      as="footer"
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; as: string; id: any; ... Remove this comment to see the full error message
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.footer}>
        <div className={css.content}>
          <div className={css.firstSection}>
            <a className={css.footerTitle}>
              <FormattedMessage id="SectionFooter.firstSectionTitle" />
            </a>
            <NamedLink
              name="LegalPage"
              className={css.footerLink}
              params={{ tab: LegalPageTab.TermsAndConditions }}
            >
              <FormattedMessage id="SectionFooter.termsOfServices" />
            </NamedLink>
            <NamedLink
              name="LegalPage"
              className={css.footerLink}
              params={{
                tab: LegalPageTab.PrivacyPolicy,
              }}
            >
              <FormattedMessage id="SectionFooter.privacyPolicy" />
            </NamedLink>
            <a href="https://intercom.help/nold-b5f233066ddb/en/" className={css.footerLink}>
              <FormattedMessage id="SectionFooter.FAQs" />
            </a>
            <NamedLink
              name="LegalPage"
              className={css.footerLink}
              params={{ tab: LegalPageTab.Cookies }}
            >
              <FormattedMessage id="SectionFooter.cookies" />
            </NamedLink>
            <a href="#" className={`${css.footerLink} termly-display-preferences`}>
              Consent Preferences
            </a>
          </div>

          <div className={css.secondSection}>
            <a className={css.footerTitle}>
              <FormattedMessage id="SectionFooter.secondSectionTitle" />
            </a>
            <NamedLink
              name="InfoPage"
              params={{ tab: InfoPageTab.About }}
              className={css.footerLink}
            >
              <FormattedMessage id="SectionFooter.about" />
            </NamedLink>
            <NamedLink
              name="InfoPage"
              params={{ tab: InfoPageTab.HowItWorks }}
              className={css.footerLink}
            >
              <FormattedMessage id="SectionFooter.howItWorks" />
            </NamedLink>

            <NamedLink
              name="InfoPage"
              params={{ tab: InfoPageTab.Nolder }}
              className={css.footerLink}
            >
              <FormattedMessage id="SectionFooter.nolder" />
            </NamedLink>
            <NamedLink
              name="InfoPage"
              params={{ tab: InfoPageTab.Contact }}
              className={css.footerLink}
            >
              <FormattedMessage id="SectionFooter.contact" />
            </NamedLink>
          </div>
          <div className={css.thirdSection}>
            <a className={css.footerTitle}>
              <FormattedMessage id="SectionFooter.thirdSectionTitle" />
            </a>
            <form onSubmit={handleSubscribe} className={css.subscribeSection}>
              <input
                name="footerSubscribe"
                type="email"
                placeholder="Enter your email address"
                required
                className={css.subscribeInput}
              />
              <PrimaryButton type="submit">
                <FormattedMessage id="SectionFooter.subscribeButton" />
              </PrimaryButton>
            </form>
            <h5 className={css.privacy}>
              <FormattedMessage id="SectionFooter.privacy" />
            </h5>
          </div>
          <div className={css.followUs}>
            <span>Follow us:</span>
            <a href="https://www.instagram.com/thenold.preloved/">
              <IconInstagram style={{ color: 'white', width: 30, height: 30 }} />
            </a>
          </div>
        </div>
      </div>

      <div className={css.secondFooter}>
        <div className={css.secondFooterContainer}>
          <FormattedMessage
            id="SectionFooter.secondFooter"
            values={{
              anchor: <a href="mailto:hello@thenold.com">hello@thenold.com</a>,
              currentYear: new Date().getFullYear(),
            }}
          />
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  appearance: null,
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  appearance: object,
  options: propTypeOption,
};

export default SectionFooter;
