import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';

import { ExternalLink } from '../../../../components';
import {
  facebookIcon,
  instagramIcon,
  linkedinIcon,
  pinterestIcon,
  tiktokIcon,
  twitterIcon,
  youtubeIcon,
} from './Icons';
import css from './SocialMediaLink.module.css';

const ICON_CONF = {
  facebook: facebookIcon,
  instagram: instagramIcon,
  linkedin: linkedinIcon,
  pinterest: pinterestIcon,
  tiktok: tiktokIcon,
  twitter: twitterIcon,
  youtube: youtubeIcon,
};

const getIconConf = platform => {
  const icon = ICON_CONF[platform] || null;
  return icon;
};

export const supportedPlatforms = [
  'facebook',
  'instagram',
  'linkedin',
  'pinterest',
  'tiktok',
  'twitter',
  'youtube',
];

export const SocialMediaLink = React.forwardRef((props, ref) => {
  const Icon = getIconConf(props.children);

  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
  const { className, rootClassName, href, title, children } = props;
  const classes = classNames(rootClassName || css.link, className);
  const titleMaybe = title ? { title } : {};
  const iconOrChildren = Icon ? <Icon /> : children;
  const linkProps = { className: classes, href, children: iconOrChildren, ...titleMaybe };

  // Markdown parser (rehype-sanitize) might return undefined href
  if (!href || !children) {
    return null;
  }

  // @ts-expect-error TS(2322) FIXME: Type '{ ref: ForwardedRef<unknown>; title: any; cl... Remove this comment to see the full error message
  return <ExternalLink {...linkProps} ref={ref} />;
});

SocialMediaLink.displayName = 'SocialMediaLink';

SocialMediaLink.defaultProps = {
  // @ts-expect-error TS(2322) FIXME: Type '{ title: null; rootClassName: null; classNam... Remove this comment to see the full error message
  title: null,
  rootClassName: null,
  className: null,
};

SocialMediaLink.propTypes = {
  // @ts-expect-error TS(2322) FIXME: Type '{ title: Requireable<string>; rootClassName:... Remove this comment to see the full error message
  title: string,
  rootClassName: string,
  className: string,
  children: node.isRequired,
  href: string.isRequired,
};
