import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';

import css from './Code.module.css';

const defaultPropsCode = {
  rootClassName: null,
  className: null,
};

const propTypesCode = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
};

/**
 * HTML element <code> represents an inline code.
 * It is marked in markdown with single backticks: some `inline code`
 */
export const Code = React.forwardRef((props, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
  const { className, rootClassName, ...otherProps } = props;
  const classes = classNames(rootClassName || css.code, className);

  // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
  return <code className={classes} {...otherProps} ref={ref} />;
});
Code.displayName = 'Code';
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: null; className: null; }' h... Remove this comment to see the full error message
Code.defaultProps = defaultPropsCode;
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: Requireable<string>; classN... Remove this comment to see the full error message
Code.propTypes = propTypesCode;

/**
 * HTML element <pre> represents a preformatted text.
 * Codeblock in markdown is rendered with <pre> tag.
 */
export const CodeBlock = React.forwardRef((props, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
  const { className, rootClassName, ...otherProps } = props;
  const classes = classNames(rootClassName || css.codeBlock, className);

  // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
  return <pre className={classes} {...otherProps} ref={ref} />;
});
CodeBlock.displayName = 'CodeBlock';
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: null; className: null; }' h... Remove this comment to see the full error message
CodeBlock.defaultProps = defaultPropsCode;
// @ts-expect-error TS(2559) FIXME: Type '{ rootClassName: Requireable<string>; classN... Remove this comment to see the full error message
CodeBlock.propTypes = propTypesCode;
